// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/pii/manual_idv_attempt.proto (package kikoff_canada.protobuf.models.pii, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { FullName } from "../../types/pii/full_name_pb.js";
import { Address } from "../../types/pii/address_pb.js";
import { Attachment } from "../../types/attachment_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.pii.ManualIdvAttempt
 */
export class ManualIdvAttempt extends Message<ManualIdvAttempt> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: kikoff_canada.protobuf.types.pii.FullName full_name = 2;
   */
  fullName?: FullName;

  /**
   * @generated from field: kikoff_canada.protobuf.types.pii.Address address = 3;
   */
  address?: Address;

  /**
   * @generated from field: string birth_date = 4;
   */
  birthDate = "";

  /**
   * @generated from field: kikoff_canada.protobuf.types.Attachment photo_id = 5;
   */
  photoId?: Attachment;

  /**
   * @generated from field: kikoff_canada.protobuf.types.Attachment selfie = 6;
   */
  selfie?: Attachment;

  /**
   * @generated from field: google.protobuf.Timestamp uploaded_at = 7;
   */
  uploadedAt?: Timestamp;

  constructor(data?: PartialMessage<ManualIdvAttempt>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.pii.ManualIdvAttempt";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "message", T: FullName },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "birth_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "photo_id", kind: "message", T: Attachment },
    { no: 6, name: "selfie", kind: "message", T: Attachment },
    { no: 7, name: "uploaded_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ManualIdvAttempt {
    return new ManualIdvAttempt().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ManualIdvAttempt {
    return new ManualIdvAttempt().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ManualIdvAttempt {
    return new ManualIdvAttempt().fromJsonString(jsonString, options);
  }

  static equals(a: ManualIdvAttempt | PlainMessage<ManualIdvAttempt> | undefined, b: ManualIdvAttempt | PlainMessage<ManualIdvAttempt> | undefined): boolean {
    return proto3.util.equals(ManualIdvAttempt, a, b);
  }

  static _enums: {
  };
}

